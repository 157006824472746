// See https://jqueryvalidation.org/files/demo/bootstrap/index.html

jQuery.validator.setDefaults({
	errorElement: "div",
	errorPlacement: function (error, element) {
		error.addClass("invalid-feedback");
		element.closest("div:not(.iti):not(.form-check)").append(error);
	},
	highlight: function (element, errorClass, validClass) {
        element.setCustomValidity("invalid");
		$(element.form).find("[name='" + $(element).attr("name") + "']").removeClass("is-valid").addClass("is-invalid");
	},
	unhighlight: function (element, errorClass, validClass) {
        element.setCustomValidity("");
		$(element.form).find("[name='" + $(element).attr("name") + "']").removeClass("is-invalid").addClass("is-valid");
	},
	submitHandler: function (form) {
		if (this.wasSubmitted)  // Prevent multiple form submissions
			return;
		this.wasSubmitted = true;
		if (grecaptcha)
			grecaptcha.execute();
		else
			form.submit();
	}
});

jQuery.validator.addMethod("ccExpiry", function (value, element) {
	var dateParts = value.split("/");
	var expiry = new Date(20+dateParts[1], dateParts[0]-1, 1, 0, 0, 0);
	console.log(expiry);
	return (expiry > new Date());
}, 'Invalid date.');

jQuery.validator.addMethod("dateRange", function(value, element, param) {
	if (!jQuery.validator.methods.dateISO.call(this, value, element))
	{
		return false;
	}
	var date = new Date(value);
	var dateFrom = new Date(param[0]);
	var dateTo = new Date(param[1]);
	return !value.length || (date >= dateFrom && date <= dateTo);
}, "Date must be between {0} and {1}.");

jQuery.validator.addMethod("alphanumeric", function(value, element) {
		return /^\w*$/i.test(value);
}, "Letters, digits, and underscores only please");

jQuery.validator.addMethod("emailPwire", function(value, element) {
		return jQuery.validator.methods.email.call(this, value.replace(/\;$/, ''), element);
}, "Please enter a valid email address.");

jQuery.validator.addMethod("phoneNumber", function (value, element) {
	var iti = window.intlTelInput.getInstance(element);
	if (element.dataset.allowExtension == "false" && iti.getExtension())
	{
		return false;
	}
	return !value.length || iti.isValidNumberPrecise() !== false;
}, "Invalid phone number.");

jQuery.validator.addMethod("postalCode", function (value, element) {
	var formats = {USA: "\\d{5}(-\\d{4})?"};
	var country = element.dataset.country ? element.dataset.country : "USA";
	var format = formats[country] ? formats[country] : ".*";
	var regex = new RegExp("^" + format + "$");
	return regex.test(value.toUpperCase());
}, "Invalid postal code.");

jQuery.validator.addMethod("routingNumber", function (value, element) {
	d = value.split("").map(function(t){return parseInt(t)});
	return !value.length || /^((0[1-9])|(1[0-2])|(2[1-9])|(3[0-2]))[0-9]{7}$/.test(value) && (3*(d[0]+d[3]+d[6])+7*(d[1]+d[4]+d[7])+d[2]+d[5]+d[8]) % 10 === 0;
}, "Invalid routing number");


// Forms

$("form:not([novalidate])").each(function () {
	$(this).validate();
});
